import React from 'react';
import customO from '../iconBackgroundless.png'

function HeaderBlock() {
  return (
    <div className="header-block">
      {/* BeanB<span className="custom-o"><img src={customO} alt="o" /></span>nus */}
      BeanBonus
    </div>
  );
}

export default HeaderBlock;
