import React from 'react';
import CoffeeRain from './components/CoffeeRain';
import LottieBlock from './components/LottieBlock';
import ParallaxBlock from './components/ParallaxBlock';
import foreground1 from './for_web/beanbonus_website_homescreen_02112025.png'
import foreground2 from './for_web/map_for_web_2.png'
import foreground3 from './for_web/cafe_for_web.png'
import foreground4 from './for_web/specials_for_web.png'
import foreground5 from './for_web/beanbonus_website_tap_02112025.png'
import HeaderBlock from './components/HeaderBlock';
import CafeFormBlock from './components/CafeFormBlock';
import CollidingBlock from './components/CollidingTap'
import './Block.css';
// import animation1 from './lotties/lottie_walkingcoffee.json' // walking coffee
// import animation1 from './lotties/lottie_dayold.json' // play around with
import animation1 from './lotties/lottie_bell.json'
import animation3 from './lotties/connectcafe.json'
import animation4 from './lotties/connect.json'
import animation5 from './lotties/coffee_loading.json'
import animation_punch_card from './lotties/lottie_loyalty_card_intro.json'
import animation_getpaid from './lotties/lottie_getpaid.json'
import animation_go_green from './lotties/gogreen.json'
import IntroVideo from './components/IntroVideo';
import videoSource from './lotties/BeanBonusIntro3.mp4'
import DownloadAppButton from './components/DownloadAppButton';
import parallaxbackground from './coffeetexture.png'
import FloatingButton from './components/FloatingButton'
// testing embedded video
import VideoEmbed from './components/VideoEmbed'
import MyQRCode from './components/QRCode'
import IconWithBackground from './components/IconWithBackground'
import { faMapPin } from '@fortawesome/free-solid-svg-icons'


function LandingPage() {

    return (
        <div className="body">
            <CoffeeRain />
            <HeaderBlock />
            {/* <CollidingBlock /> */}
            {/* <IconWithBackground icon={faMapPin} size="10x" /> */}
            {/* <MyQRCode /> */}
            {/* <VideoEmbed /> */}
            {/* <IntroVideo videoSource={videoSource}/> */}
            {/* commenting out floating button as its too much going on overlapping on the screen, need to tighten it up */}
            {/* <FloatingButton /> */}
            <LottieBlock 
                animationData={animation_punch_card} 
                header={"The Coffee App For Every Cafe"}
                description={"none"}
                subdescription={["Time to toss out those stamp cards.", "This app collects loyalty rewards from all your cafes!", "Enjoy that free sip feeling."]}
                slide="left" />
            <DownloadAppButton />
            <ParallaxBlock backgroundImage={parallaxbackground} foregroundImageSrc={foreground1} textContent={"Digital\nStamp\nCard"} number={'one'} /> 
            <LottieBlock 
                animationData={animation1} 
                header={"Save Up To 90%"}
                description={"none"}
                subdescription={["Stay notified when day-olds hit the shelves.", "Donuts for 50-cents, muffins for a dollar, save big on baked goods.", "Be the first to know and never miss out."]}
                slide="left" />
            <ParallaxBlock backgroundImage={parallaxbackground} foregroundImageSrc={foreground2} textContent={"Explore\nNew\nGems"} number={'two'} /> 
            <LottieBlock 
                animationData={animation_getpaid} 
                header={"Get Paid Cash"} 
                description={"none"}
                subdescription={["Explore cafes to earn beans.", "Our best explorers get their cards loaded up at the end of each month.", "Either cash out or use it for coffees and treats!"]}
                slide="left" />
            <ParallaxBlock backgroundImage={parallaxbackground} foregroundImageSrc={foreground3} textContent={"Fresh\nDay\nOlds"} number={'three'} />       
            <LottieBlock
                animationData={animation_go_green} 
                header={"Go Green"} 
                description={"none"}
                subdescription={["Get rid of those pesky punch cards.", "Help reduce waste from extra baked goods.", "Become eco-friendly with BeanBonus."]}
                slide="left" />
            <ParallaxBlock backgroundImage={parallaxbackground} foregroundImageSrc={foreground4} textContent={"Special\nOffers"} number={'four'} />
            <LottieBlock 
                animationData={animation3} 
                header={"Support Your Community"} 
                description={"none"}
                subdescription={["Help small businesses thrive by choosing local.", "Support artisanal craftsmanship.", "Never miss any events by knowing when and where all the latest buzz is happening!"]}
                slide="right" />
            <ParallaxBlock backgroundImage={parallaxbackground} foregroundImageSrc={foreground5} textContent={"Tap\nand Go"} number={'one'} /> 
            <LottieBlock 
                animationData={"none"} 
                header="What Are You Waiting For?" 
                description={"none"}
                subdescription={["Find us on the App Store!", "Contact Us", "Phone: 416-500-5506", "Email: info@beanbonus.ca"]}
                slide="right" />
            <DownloadAppButton />
            <CafeFormBlock />
        </div>
    );
}

export default LandingPage;