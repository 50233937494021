import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import '../DownloadButton.css';
import AppStoreIcon from '../DownloadApp.svg';
import { useInView } from 'react-intersection-observer';


const DownloadAppButton = () => {

  return (
    <div className="download-container" style={{ position: 'relative', display: 'inline-block', textAlign: 'center' }}>
      <div style={{ justifyContent: 'center', alignContent: 'center', paddingBottom: 15 }}>
        <FontAwesomeIcon icon={faArrowDown} size="2x" style={{ color: '#f48e28', transform: 'rotate(-20deg)', marginRight: '5vw' }}/>
        <FontAwesomeIcon icon={faArrowDown} size="2x" style={{ color: '#f48e28', transform: 'rotate(20deg)', marginLeft: '5vw' }}/>
        {/* <FontAwesomeIcon icon={faArrowTrendUp} size="2x" style={{ color: '#f48e28', transform: 'rotate(160deg)', marginLeft: '20vw' }}/> */}
      </div>
      <a
        href="https://apps.apple.com/app/id6479172588"
        target="_blank"
        rel="noopener noreferrer"
        className="download-button"
      >
        <img src={AppStoreIcon} alt="App Store Icon" style={{ verticalAlign: 'middle', maxHeight: '10vh' }} />
      </a>
      <div style={{ justifyContent: 'center', alignContent: 'center', paddingTop: 15, paddingBottom: 15 }}>
        <FontAwesomeIcon icon={faArrowUp} size="2x" style={{ color: '#f48e28', transform: 'rotate(20deg)', marginRight: '5vw' }}/>
        <FontAwesomeIcon icon={faArrowUp} size="2x" style={{ color: '#f48e28', transform: 'rotate(-20deg)', marginLeft: '5vw' }}/>
        {/* <FontAwesomeIcon icon={faArrowTrendUp} size="2x" style={{ color: '#f48e28', transform: 'rotate(-20deg)', marginRight: '20vw' }}/>
        <FontAwesomeIcon icon={faArrowTrendDown} size="2x" style={{ color: '#f48e28', transform: 'rotate(200deg)', marginLeft: '20vw' }}/> */}
      </div>
    </div>
  );
}

export default DownloadAppButton;
